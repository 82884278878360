import React from 'react';
import {
  MoreFormatter,
  controlBoxFormatter,
  serviceStatusFormatter,
} from '../../utils/formatters';
import { Icons } from '../../components/base/BootstrapComponents';

export const serviceStatusOptions = [
  { value: 0, nameId: 'Статус' },
  { value: 1, nameId: 'Активен' },
  { value: 2, nameId: 'Стоп' },
  { value: -1, nameId: 'Деактивирован' }];

export const serviceOptions = [
  { value: 0, nameId: 'Сервисы' },
  { value: 1, nameId: 'Очередь' },
  { value: 2, nameId: 'База' },
  { value: -1, nameId: 'Контроллер' }];

export const defaultFilterValues = {
  service: '',
  serviceStatus: '',
};

export const defaultSettingColumnValues = {
  service: true,
  serviceStatus: true,
};

export const defaultColumns = [
  {
    dataField: 'checkBox',
    text: 'Выбор строки',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-0 text-center column-relative',
    dataClassName: 'align-middle column-relative',
  },
  {
    dataField: 'service',
    text: 'Сервисы',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-1',
    dataClassName: 'align-middle',
  },
  {
    dataField: 'serviceStatus',
    text: 'Статус',
    disableSort: true,
    dataClassName: 'align-middle',
    formatter: serviceStatusFormatter,
    headerClassName: 'column-2',
    hidden: true,
  },
  {
    dataField: 'control',
    text: 'Доступные действия',
    formatter: controlBoxFormatter,
    disableSort: true,
    dataClassName: 'align-middle text-center',
    headerClassName: 'align-middle column-3',
    hidden: true,
  },
];

export const data = [
  {
    id: '2323fd',
    service: 'База',
    serviceStatus: 1,
    control: [
      'stop',
      'reset',
    ],
    setting: {},
  },
  {
    id: '23f23d',
    service: 'Очередь',
    serviceStatus: 2,
    control: [
      'play',
      'reset',
    ],
    setting: {},
  },
  {
    id: '2334fd',
    service: 'Контроллер',
    serviceStatus: -1,
    control: [
      'stop',
      'reset',
    ],
    setting: {},
  },
];
