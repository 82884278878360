import React from 'react';
import {
  MoreFormatter,
  sessionNumberFormatter,
  clientTypeFormatter,
  sessionStatusFormatter,
  dateOrDateTimeFormatter,
} from '../../utils/formatters';
import CheckBoxInput from '../../components/fields/CheckBoxInput/CheckBoxInput';
import { Icons } from '../../components/base/BootstrapComponents';

export const zonesOptions = [
  { value: 0, name: 'Зона клиента' },
  { value: 1, name: 'Общая зона' },
  { value: 2, name: 'VIP зона' },
  { value: 3, name: 'Зона перрона убытия' },
  { value: 4, name: 'Долговременная зона' },
  { value: 5, name: 'Зона перрона прибытия' }];

export const statusSessionOptions = [
  { value: 0, nameId: 'Статус сессии' },
  { value: 1, nameId: 'Активна' },
  { value: 2, nameId: 'Завершена' },
];

export const defaultFilterValues = {
  query: '',
  sessionNumber: '',
  clientId: '',
  clientZone: '',
  clientType: '',
  dataStart: '',
  dataEnd: '',
  sessionStatus: '',
  lastOperation: '',
  governmentNumber: '',
  paymentAmount: '',
};

export const updatePeriods = [
  { value: 0, name: 'Не обновлять', icon: 'update-period-0' },
  { value: 10, name: '10 секунд', icon: 'update-period-10' },
  { value: 30, name: '30 секунд', icon: 'update-period-30' },
  { value: 60, name: '1 минута', icon: 'update-period-60' },
  { value: 300, name: '5 минут', icon: 'update-period-300' },
];

export const defaultColumns = [
  {
    dataField: 'checkBox',
    text: 'Выбор строки',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-0 text-center column-relative column-relative',
    dataClassName: 'align-middle column-relative',
  },
  {
    dataField: 'sessionNumber',
    text: 'Номер сессии',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-1',
    formatter: sessionNumberFormatter,
    dataClassName: 'align-middle',
  },
  {
    dataField: 'clientId',
    text: 'ИД клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-2',
    hidden: true,
  },
  {
    dataField: 'clientZone',
    text: 'Зона клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-3',
    hidden: true,
  },
  {
    dataField: 'clientType',
    text: 'Тип клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-4',
    formatter: clientTypeFormatter,
    hidden: true,
  },
  {
    dataField: 'dataStart',
    text: 'Дата и время начала',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-5',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'dataEnd',
    text: 'Дата и время окончания',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-6',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'sessionStatus',
    text: 'Статус сессии',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-7',
    formatter: sessionStatusFormatter,
    hidden: true,
  },
  {
    dataField: 'lastOperation',
    text: 'Последняя операция',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-8',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'governmentNumber',
    text: 'Номер ГРЗ',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-9',
    hidden: true,
  },
  {
    dataField: 'paymentAmount',
    text: 'Сумма оплаты, руб',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-10',
    hidden: true,
  },
];

export const data = [
  {
    id: '23fdfdd',
    sessionNumber: 535,
    clientId: 'KF1235FG',
    clientZone: 'Зона клиента',
    clientType: 'Разовый',
    dataStart: '2024-06-03T10:15:30+03:00',
    dataEnd: '2024-07-04T10:15:30+03:00',
    sessionStatus: 1,
    lastOperation: '2024-07-03T10:15:30+03:00',
    governmentNumber: 'D000FG777',
    paymentAmount: '300,00',
    setting: {},
  },
  {
    id: '23fewdd',
    sessionNumber: 345,
    clientId: 'KF1235FG',
    clientZone: 'Зона клиента',
    clientType: 'A 2455656547',
    dataStart: '2024-06-03T10:15:30+03:00',
    dataEnd: '2024-07-03T10:15:30+03:00',
    sessionStatus: 1,
    lastOperation: '2024-07-03T10:15:30+03:00',
    governmentNumber: 'D000FG777',
    paymentAmount: '300,00',
    setting: {},
  },
  {
    id: '23f34dd',
    sessionNumber: 234,
    clientId: 'KF1235FG',
    clientZone: 'Зона клиента',
    clientType: 'Р 2455656547',
    dataStart: '2024-06-03T10:15:30+03:00',
    dataEnd: '2024-07-03T10:15:30+03:00',
    sessionStatus: 2,
    lastOperation: '2024-07-03T10:15:30+03:00',
    governmentNumber: 'D000FG777',
    paymentAmount: '300,00',
    setting: {},
  },
  {
    id: '23f34dd',
    sessionNumber: 3452,
    clientId: 'KF1235FG',
    clientZone: 'Зона клиента',
    clientType: 'Р 2455656547',
    dataStart: '2024-06-03T10:15:30+03:00',
    dataEnd: '2024-07-03T10:15:30+03:00',
    sessionStatus: 2,
    lastOperation: '2024-07-03T10:15:30+03:00',
    governmentNumber: 'D000FG777',
    paymentAmount: '300,00',
    setting: {},
  },
  {
    id: '23f34dd',
    sessionNumber: 3452,
    clientId: 'KF1235FG',
    clientZone: 'Зона клиента',
    clientType: 'Р 2455656547',
    dataStart: '2024-06-03T10:15:30+03:00',
    dataEnd: '2024-07-03T10:15:30+03:00',
    sessionStatus: 2,
    lastOperation: '2024-07-03T10:15:30+03:00',
    governmentNumber: 'D000FG777',
    paymentAmount: '308,00',
    setting: {},
  },
  {
    id: '23f34dd',
    sessionNumber: 1234,
    clientId: 'KF1235FG',
    clientZone: 'Зона клиента',
    clientType: 'Р 2455656547',
    dataStart: '2024-06-03T10:15:30+03:00',
    dataEnd: '2024-07-03T10:15:30+03:00',
    sessionStatus: 1,
    lastOperation: '2024-07-03T10:15:30+03:00',
    governmentNumber: 'D000FG777',
    paymentAmount: '300,00',
    setting: {},
  },
  {
    id: '23f34dd',
    sessionNumber: 3466,
    clientId: 'KF1235FG',
    clientZone: 'Зона клиента',
    clientType: 'Р 2455656547',
    dataStart: '2024-06-03T10:15:30+03:00',
    dataEnd: '2024-07-03T10:15:30+03:00',
    sessionStatus: 1,
    lastOperation: '2024-07-03T10:15:30+03:00',
    governmentNumber: 'D000FG777',
    paymentAmount: '320,00',
    setting: {},
  },
  {
    id: '23f34dd',
    sessionNumber: 9354,
    clientId: 'KF1235FG',
    clientZone: 'Зона клиента',
    clientType: 'Р 2455656547',
    dataStart: '2024-06-03T10:15:30+03:00',
    dataEnd: '2024-07-03T10:15:30+03:00',
    sessionStatus: 1,
    lastOperation: '2024-07-03T10:15:30+03:00',
    governmentNumber: 'D000FG777',
    paymentAmount: '300,00',
    setting: {},
  },
];
