export const CLIENT_MODULE = 'CLIENT';
export const SYSTEM_MODULE = 'SYSTEM';
export const GUEST_MODULE = 'GUEST';
export const USER_MODULE = 'USER';
export const ROLE_MODULE = 'USER_ROLE_MODULE';
export const NOTIFICATION = 'NOTIFICATION';
export const HOSTS_MODULE = 'HOSTS';
export const CONFIG_ZONES_AND_HOST_MODULE = 'CONFIG_ZONES_AND_HOST';
export const SESSION_LOG_PAGE_MODULE = 'SESSION_LOG_PAGE';
export const EVENT_LOG_PAGE_MODULE = 'EVENT_LOG_PAGE';
export const PAYMENT_LOG_PAGE_MODULE = 'PAYMENT_LOG_PAGE';
export const SERVICE_MONITORING_MODULE = 'SERVICE_MONITORING';
export const CLIENTS_MODULE = 'CLIENTS';
export const ZONES_PAGE_MODULE = 'ZONES_PAGE';
