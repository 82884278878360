import React from 'react';
import classNames from 'classnames';
import './event-log-page.scss';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import CustomButton from '../../../components/base/Button/CustomButton';
import { Icons } from '../../../components/base/BootstrapComponents';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import { statusNotificationFormatter, dateTimeFormatter } from '../../../utils/formatters';

export default function EventLogPopUp(props) {
  const { onHide, selectedItem } = props;

  return (
    <Dialog
      className={classNames('elp-popup')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            {/* Требуются уточнить у дизайнера */}
            <div className="hff-title-text f2">Информация о событии</div>
            {statusNotificationFormatter(selectedItem.status)}
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={onHide} />
            </div>
          </>
        )}
      />
      <DialogContent className="hff-content">
        <FieldWithAttributeDTO
          type="text"
          disabled
          className="short"
          component={TextInputWithLabel}
          dtoObject={{
            name: 'hostName',
            type: String,
            validators: [],
            labelKey: 'elp.hostName',
          }}
          inputProps={{
            value: selectedItem.hostName,
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          disabled
          className="middle"
          component={TextInputWithLabel}
          dtoObject={{
            name: 'date',
            type: String,
            validators: [],
            labelKey: 'elp.date',
          }}
          inputProps={{
            value: dateTimeFormatter(selectedItem.date),
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          disabled
          className="short"
          component={TextInputWithLabel}
          dtoObject={{
            name: 'clientId',
            type: String,
            validators: [],
            labelKey: 'elp.clientId',
          }}
          inputProps={{
            value: selectedItem.clientId,
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          disabled
          className="short"
          component={TextInputWithLabel}
          dtoObject={{
            name: 'couponNumber',
            type: String,
            validators: [],
            labelKey: 'elp.couponNumber',
          }}
          inputProps={{
            value: selectedItem.couponNumber,
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          disabled
          className="short"
          component={TextInputWithLabel}
          dtoObject={{
            name: 'sessionNumber',
            type: String,
            validators: [],
            labelKey: 'elp.sessionNumber',
          }}
          inputProps={{
            value: selectedItem.sessionNumber,
          }}
        />
        <FieldWithAttributeDTO
          type="text"
          disabled
          component={TextInputWithLabel}
          dtoObject={{
            name: 'event',
            type: String,
            validators: [],
            labelKey: 'elp.event',
          }}
          inputProps={{
            value: selectedItem.event,
          }}
        />
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton text="Копировать" onClick={() => {}} />
        <CustomButton isCancel text="Закрыть" onClick={() => { onHide(); }} />
      </DialogFooter>
    </Dialog>
  );
}
