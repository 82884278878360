import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './service-monitoring-page.scss';
import { Icons } from '../../../components/base/BootstrapComponents';
import {
  serviceStatusOptions, serviceOptions, defaultFilterValues, defaultSettingColumnValues, data as serviceMonitoringList, defaultColumns,
} from '../moks';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import ServiceMonitoringGrid from './ServiceMonitoringGrid';
import SettingColumn from '../../../components/base/SettingColumn/SettingColumn';
import IconBox from '../../../components/common/IconBox';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';

export default function ServiceMonitoringPage() {
  const [data, setData] = useState(serviceMonitoringList);
  const [isOpenSettingColumn, setSettingColumn] = useState(false);
  const sessionLogColumn = getColumnsForTable(defaultColumns);

  const loadDataFromStorage = () => {
    const storage = JSON.parse(localStorage.getItem('columns-filter'));
    return storage ? storage.map(column => {
      const search = sessionLogColumn.find(e => e.dataField === column.name);
      return { ...search, ...column };
    }).sort((a, b) => a.top - b.top) : sessionLogColumn;
  };

  const [columns, setColumns] = useState(loadDataFromStorage());

  const {
    register, getValues, watch, setValue, reset, resetField,
  } = useForm({
    defaultValues: JSON.parse(localStorage.getItem('sm-filter')) || { ...defaultFilterValues },
  });

  watch();

  return (
    <div className="service-monitoring-page">
      <div className="sm-container">
        <div className="sm-inputs-box">
          <PageHeader header="Мониторинг сервисов" />
          <div className="sm-inputs">
            <FieldWithAttributeDTO
              type="text"
              dtoObject={{
                name: 'service',
                type: String,
                validators: [],
                labelKey: 'sm.service',
              }}
              inputProps={{
                ...register('service', { maxLength: 30 }),
                onChange: e => {
                  setValue('service', e.target.value);
                  localStorage.setItem('sm-filter', JSON.stringify({ ...getValues() }));
                },
                value: Number(getValues('service')) || '',
              }}
              component={CustomSelectInput}
              options={serviceOptions}
              className="no-border select-field"
            />
            <FieldWithAttributeDTO
              type="text"
              dtoObject={{
                name: 'serviceStatus',
                type: String,
                validators: [],
                labelKey: 'sm.serviceStatus',
              }}
              inputProps={{
                ...register('serviceStatus', { maxLength: 30 }),
                onChange: e => {
                  setValue('serviceStatus', e.target.value);
                  localStorage.setItem('sm-filter', JSON.stringify({ ...getValues() }));
                },
                value: Number(getValues('serviceStatus')) || '',
              }}
              component={CustomSelectInput}
              options={serviceStatusOptions}
              className="no-border select-field"
            />
            <SeparateBox />
            <IconBox icon="export" />
            {isOpenSettingColumn
              ? (
                <SettingColumn
                  onHide={() => { setSettingColumn(false); }}
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  reset={reset}
                  setColumns={setColumns}
                  columns={columns}
                  defaultValues={defaultSettingColumnValues}
                />
              )
              : null}
          </div>
        </div>
        <ServiceMonitoringGrid data={data} setData={setData} columns={defaultColumns.filter(e => e.hidden !== false)} setSettingColumn={setSettingColumn} />
      </div>
    </div>
  );
}
