import React from 'react';
import {
  MoreFormatter,
  govermentNumberTextFormatter,
  clientTypeFormatter,
  dateOrDateTimeFormatter,
  clientStatusFormatter,
  controlBoxFormatter,
} from '../../utils/formatters';
import { Icons } from '../../components/base/BootstrapComponents';

export const zonesOptions = [
  { value: 1, name: 'Общая зона' },
  { value: 2, name: 'VIP зона' },
  { value: 3, name: 'Зона перрона убытия' },
  { value: 4, name: 'Долговременная зона' },
  { value: 5, name: 'Зона перрона прибытия' }];

export const typeClientOptions = [
  { value: 0, name: 'Тип клиента' },
  { value: 1, name: 'Общая зона' },
  { value: 2, name: 'Активен за территорией' },
  { value: -1, name: 'Заблокирован' }];

export const groupsOptions = [
  { value: 1, name: 'Группа 1' },
  { value: 2, name: 'Группа 2' },
  { value: 3, name: 'Группа 3' },
  { value: 4, name: 'Группа 4' },
  { value: 5, name: 'Группа 5' }];

export const defaultFilterValues = {
  id: '',
  clientId: '',
  typeClient: '',
  govermentNumber: '',
  zone: '',
  dateStart: '',
  dateEnd: '',
  clientStatus: '',
};

export const defaultColumns = [
  {
    dataField: 'checkBox',
    disableSort: true,
    text: 'Выбор строки',
    hidden: true,
    headerClassName: 'column-0 text-center column-relative column-relative',
    dataClassName: 'align-middle column-relative',
  },
  {
    dataField: 'clientId',
    text: 'ИД клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-1',
    hidden: true,
  },
  {
    dataField: 'typeClient',
    text: 'Тип клиента',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-2',
    formatter: clientTypeFormatter,
    dataClassName: 'align-middle',
  },
  {
    dataField: 'govermentNumber',
    text: 'Номер ГРЗ',
    disableSort: true,
    hidden: true,
    formatter: govermentNumberTextFormatter,
    headerClassName: 'column-3',
    dataClassName: 'align-middle',
  },
  {
    dataField: 'zone',
    text: 'Зона клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-4',
    hidden: true,
  },
  {
    dataField: 'dateStart',
    text: 'Срок действия с',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-5',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'dateEnd',
    text: 'Срок действия по',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-6',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
  },
  {
    dataField: 'clientStatus',
    text: 'Статус',
    disableSort: true,
    headerClassName: 'column-7',
    dataClassName: 'align-middle',
    formatter: clientStatusFormatter,
    hidden: true,
  },
  {
    dataField: 'control',
    text: 'Доступные действия',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-8',
    formatter: controlBoxFormatter,
    hidden: true,
  },
  {
    dataField: 'cardNumber',
    text: 'Номер карты',
    disableSort: true,
    headerClassName: 'column-7',
    dataClassName: 'align-middle',
    hidden: false,
  },
  {
    dataField: 'dateOfUse',
    text: 'Срок действия',
    disableSort: true,
    headerClassName: 'column-11',
    dataClassName: 'align-middle',
    hidden: false,
  },
  {
    dataField: 'feedback',
    text: 'Комментарий',
    disableSort: true,
    headerClassName: 'column-10',
    dataClassName: 'align-middle',
    hidden: false,
  },
  // {
  //   dataField: 'setting',
  //   text: <Icons width={24} height={24} customIcon icon="gear" className="button-icons more-vert" />,
  //   formatter: MoreFormatter,
  //   disableSort: true,
  //   dataClassName: 'row-full-click',
  //   headerClassName: 'text-center column-9',
  //   // onClick: () => setSetting(true),
  //   hidden: true,
  //   disable: true,
  // },
];

export const data = [
  {
    id: '23fdd3',
    clientId: '21254878',
    typeClient: 'Р 2455656547',
    govermentNumber: {
      type: 'police',
      number: '8234',
      char: 'АЕ',
      charSecond: '',
      region: 23,
      country: 'RUS',
    },
    zone: 'Общая',
    dateStart: '2023-12-03T10:15:30+03:00',
    dateEnd: '2023-12-03T10:15:30+03:00',
    dateOfUse: '03.03.2023 - 03.03.2023',
    cardNumber: '44453234534523456',
    clientStatus: 1,
    control: [
      'views-details',
      'pencile',
      'trash-active',
    ],
    feedback: 'Отличный отзыв',
  },
  {
    id: '23fd65d3',
    clientId: '21254878',
    typeClient: 'А 2455656547',
    govermentNumber: {
      type: 'police',
      number: '8234',
      char: 'АЕ',
      charSecond: '',
      region: 23,
      country: 'RUS',
    },
    zone: 'Общая',
    dateStart: '2023-12-03T10:15:30+03:00',
    dateEnd: '2023-12-03T10:15:30+03:00',
    cardNumber: '44453234534523456',
    dateOfUse: '03.03.2023 - 03.03.2023',
    clientStatus: -1,
    control: [
      'views-details',
      'pencile',
      'trash-active',
    ],
    feedback: 'Отличный отзыв',
  },
  {
    id: '23fkj5',
    clientId: '21254878',
    typeClient: 'А 2455656547',
    govermentNumber: {
      type: 'police',
      number: '8234',
      char: 'АЕ',
      charSecond: '',
      region: 23,
      country: 'RUS',
    },
    zone: 'Общая',
    dateStart: '2023-12-03T10:15:30+03:00',
    cardNumber: '44453234534523456',
    dateEnd: '2023-12-03T10:15:30+03:00',
    dateOfUse: '03.03.2023 - 03.03.2023',
    clientStatus: 2,
    control: [
      'views-details',
      'pencile',
      'trash-active',
    ],
    feedback: 'Отличный отзыв',
  },
  {
    id: '23fkj5',
    clientId: '21254878',
    typeClient: 'А 2455656547',
    govermentNumber: {
      type: 'police',
      number: '8234',
      char: 'АЕ',
      charSecond: '',
      region: 23,
      country: 'RUS',
    },
    zone: 'Общая',
    dateStart: '2023-12-03T10:15:30+03:00',
    cardNumber: '44453234534523456',
    dateEnd: '2023-12-03T10:15:30+03:00',
    dateOfUse: '03.03.2023 - 03.03.2023',
    clientStatus: 2,
    control: [
      'views-details',
      'pencile',
      'trash-active',
    ],
    feedback: 'Отличный отзыв',
  },
  {
    id: '23fkj5',
    clientId: '21254878',
    typeClient: 'А 2455656547',
    govermentNumber: {
      type: 'police',
      number: '8234',
      char: 'АЕ',
      charSecond: '',
      region: 23,
      country: 'RUS',
    },
    zone: 'Общая',
    dateStart: '2023-12-03T10:15:30+03:00',
    cardNumber: '44453234534523456',
    dateEnd: '2023-12-03T10:15:30+03:00',
    dateOfUse: '03.03.2023 - 03.03.2023',
    clientStatus: 2,
    control: [
      'views-details',
      'pencile',
      'trash-active',
    ],
    feedback: 'Отличный отзыв',
  },
  {
    id: '23fkj5',
    clientId: '21254878',
    typeClient: 'А 2455656547',
    govermentNumber: {
      type: 'police',
      number: '8234',
      char: 'АЕ',
      charSecond: '',
      region: 23,
      country: 'RUS',
    },
    zone: 'Общая',
    dateStart: '2023-12-03T10:15:30+03:00',
    cardNumber: '44453234534523456',
    dateEnd: '2023-12-03T10:15:30+03:00',
    dateOfUse: '03.03.2023 - 03.03.2023',
    clientStatus: 2,
    control: [
      'views-details',
      'pencile',
      'trash-active',
    ],
    feedback: 'Отличный отзыв',
  },
  {
    id: '23fkj5',
    clientId: '21254878',
    typeClient: 'А 2455656547',
    govermentNumber: {
      type: 'police',
      number: '8234',
      char: 'АЕ',
      charSecond: '',
      region: 23,
      country: 'RUS',
    },
    zone: 'Общая',
    dateStart: '2023-12-03T10:15:30+03:00',
    cardNumber: '44453234534523456',
    dateEnd: '2023-12-03T10:15:30+03:00',
    dateOfUse: '03.03.2023 - 03.03.2023',
    clientStatus: 1,
    control: [
      'views-details',
      'pencile',
      'trash-active',
    ],
    feedback: 'Отличный отзыв',
  },
  {
    id: '23fkj5',
    clientId: '21254878',
    typeClient: 'А 2455656547',
    govermentNumber: {
      type: 'police',
      number: '8234',
      char: 'АЕ',
      charSecond: '',
      region: 23,
      country: 'RUS',
    },
    zone: 'Общая',
    dateStart: '2023-12-03T10:15:30+03:00',
    cardNumber: '44453234534523456',
    dateEnd: '2023-12-03T10:15:30+03:00',
    dateOfUse: '03.03.2023 - 03.03.2023',
    clientStatus: 2,
    control: [
      'views-details',
      'pencile',
      'trash-active',
    ],
    feedback: 'Отличный отзыв',
  },
  {
    id: '23fkj5',
    clientId: '21254878',
    typeClient: 'А 2455656547',
    govermentNumber: {
      type: 'police',
      number: '8234',
      char: 'АЕ',
      charSecond: '',
      region: 23,
      country: 'RUS',
    },
    zone: 'Общая',
    dateStart: '2023-12-03T10:15:30+03:00',
    cardNumber: '44453234534523456',
    dateEnd: '2023-12-03T10:15:30+03:00',
    dateOfUse: '03.03.2023 - 03.03.2023',
    clientStatus: 1,
    control: [
      'views-details',
      'pencile',
      'trash-active',
    ],
    feedback: 'Отличный отзыв',
  },
  {
    id: '23fkj5',
    clientId: '21254878',
    typeClient: 'А 2455656547',
    govermentNumber: {
      type: 'police',
      number: '8234',
      char: 'АЕ',
      charSecond: '',
      region: 23,
      country: 'RUS',
    },
    zone: 'Общая',
    dateStart: '2023-12-03T10:15:30+03:00',
    cardNumber: '44453234534523456',
    dateEnd: '2023-12-03T10:15:30+03:00',
    dateOfUse: '03.03.2023 - 03.03.2023',
    clientStatus: 1,
    control: [
      'views-details',
      'pencile',
      'trash-active',
    ],
    feedback: 'Отличный отзыв',
  },
];
