import React, { Component } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import ru from 'moment/locale/ru';
import Dropdown from '../../base/Dropdown/Dropdown';
import TextInput from '../TextInput/TextInput';

/**
 * Set the default moment locale
 */

const moment = extendMoment(originalMoment);
moment.locale('ru', ru);
const defaultDateFormat = 'DD.MM.YYYY';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    const { withFormat = '' } = props;
    this.dayRef = React.createRef();
    this.monthRef = React.createRef();
    this.yearsRef = React.createRef();
    this.closePopup = null;
    this.timer = null;
    this.state = {
      anchorElement: null,
      inputValue: '',
      date: null,
      inputError: '',
      format: withFormat || defaultDateFormat,
    };
  }

  componentDidMount() {
    const {
      input = {
        value: moment(),
      },
      notDefaultValue = false,
      withFormat = '',
    } = this.props;
    const { value } = input;
    if (notDefaultValue && !value) {
      return;
    }
    if (!value) {
      this.onSelect(moment(), true);
    } else if (!value.clone && !withFormat) {
      this.onSelect(moment(), true);
    } else if (withFormat) {
      if (typeof value === 'string' && value.indexOf('-') >= 0) {
        return this.setValue(moment(value).format(withFormat));
      }
      return this.setValue(moment(value, withFormat).format(withFormat));
    } else {
      return this.setValue(value);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      input: {
        value: currentValue,
      },
    } = this.props;
    const {
      input: {
        value: prevValue,
      },
    } = prevProps;
    if (currentValue !== prevValue) {
      this.setValue(currentValue);
    }
  }

  setCloseFunction = callback => {
    this.closePopup = callback;
  };

  setAnchorElement = element => {
    if (this.state.anchorElement) {
      return;
    }
    this.setState({ anchorElement: element });
  };

  setDayRef = ref => {
    this.dayRef.current = ref;
  };

  setMonthRef = ref => {
    this.monthRef.current = ref;
  };

  setYearsRef = ref => {
    this.yearsRef.current = ref;
  };

  getValue = () => {
    const {
      input = {
        value: moment(),
      },
      withFormat = '',
    } = this.props;
    const { value } = input;
    if (!value) {
      return moment();
    } if (!value.clone && !withFormat) {
      return moment();
    }
    return withFormat ? this.state.date.clone() : value.clone();
  };

  onToggleOpen = isOpen => {
    if (isOpen && this.dayRef.current) {
      this.scrollToSelectedElement(this.dayRef.current);
    }
    if (isOpen && this.monthRef.current) {
      this.scrollToSelectedElement(this.monthRef.current);
    }
    if (isOpen && this.yearsRef.current) {
      this.scrollToSelectedElement(this.yearsRef.current);
    }
  };

  scrollToSelectedElement = ref => {
    const selectedElements = ref.getElementsByClassName('selected');
    if (!selectedElements || !selectedElements[0]) {
      return;
    }
    const selected = selectedElements[0];
    ref.scrollTo(0, selected.offsetTop);
  };

  setValue = v => {
    let inputValue = '';
    let date = null;
    if (v && v.clone) {
      inputValue = v.format(this.state.format);
      date = v;
    } else if (v) {
      inputValue = v;
      date = moment(v, this.state.format, true);
    }
    this.setState({ inputValue, date, inputError: '' });
  };

  onSelect = (value, initialSelect) => {
    const {
      input, disableInitialSelect = false, notDefaultValue = false, withFormat = '',
    } = this.props;
    if (!input) {
      return;
    }
    const { onChange = () => {} } = input;
    this.setValue(value);
    if (initialSelect && (disableInitialSelect || notDefaultValue)) {
      return;
    }
    if (withFormat && value) {
      onChange(value.format(this.state.format));
      return;
    }
    onChange(value);
  };

  setCloseTimer = () => {
    this.timer = setTimeout(this.closePopup, 200);
  };

  removeTimer = () => {
    clearTimeout(this.timer);
  };

  handleSelect = option => v => () => {
    this.removeTimer();
    const value = this.getValue();
    value.set(option, v);
    this.onSelect(value);
  };

  onBlur = () => {
    const {
      input: {
        value,
      } = {
        value: '',
      },
    } = this.props;
    if (this.closePopup) {
      this.setCloseTimer();
    }
    if (this.state.inputError && value) {
      this.setValue(value);
    }
  };

  onChangeTextDate = event => {
    const { value } = event.target;
    const normalizeRow = this.normalizeDate(value, this.state.inputValue);
    this.setValue(normalizeRow);
    const date = moment(normalizeRow, this.state.format, true);
    if (date.isValid() || value === '') {
      this.onSelect(!value ? '' : date);
    } else {
      this.setState({ inputError: 'Введено не корректное значение' });
    }
  };

  normalizeDate = (value, prevValue) => {
    if (!value) { return value; }

    const valueOnlyNumbers = value.replace(/[^\d]/g, '');
    const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');

    if (valueOnlyNumbers === prevValueOnlyNumbers) { return value; }
    const day = valueOnlyNumbers.slice(0, 2);
    const month = valueOnlyNumbers.slice(2, 4);
    const year = valueOnlyNumbers.slice(4, 8);

    if (valueOnlyNumbers.length < 2) { return `${day}`; }
    if (valueOnlyNumbers.length === 2) { return `${day}.`; }
    if (valueOnlyNumbers.length < 4) { return `${day}.${month}`; }
    if (valueOnlyNumbers.length === 4) { return `${day}.${month}.`; }
    if (valueOnlyNumbers.length > 4) { return `${day}.${month}.${year}`; }
  };

  isSelect = option => v => moment(this.state.date, 'HH:mm:ss').get(option) === v;

  addZero = v => (v < 10 ? `0${v}` : v);

  renderItem = (value, onClick = () => {}, isSelected = () => {}, selectCorrection) => (
    <div
      key={value}
      className={classNames('time-item', { selected: isSelected(value + selectCorrection) })}
      onClick={onClick(value + selectCorrection)}
    >
      {this.addZero(value)}
    </div>
  );

  renderBlock = (from = 0, count = 0, onClick, isSelected, ref, className = '', selectCorrection = 0, revert = false) => {
    const list = [...Array(count)];
    if (revert) {
      return (
        <div className={classNames('time-block', className)} ref={ref}>
          {/* eslint-disable-next-line no-unused-vars */}
          {list.map((e, i) => (this.renderItem(from + count - i, onClick, isSelected, selectCorrection)))}
        </div>
      );
    }
    return (
      <div className={classNames('time-block', className)} ref={ref}>
        {/* eslint-disable-next-line no-unused-vars */}
        {list.map((e, i) => (this.renderItem(i + from, onClick, isSelected, selectCorrection)))}
      </div>
    );
  };

  render() {
    const {
      className = '',
      controlId,
      disabled = false,
      inputProps = {},
      marginDate = 0,
    } = this.props;
    const { anchorElement, inputValue, inputError } = this.state;

    let addError = {};
    if (inputError) {
      addError = {
        meta: {
          error: inputError,
          touched: true,
        },
      };
    }
    const year = Number(moment().format('YYYY'));
    return (
      <div
        className={classNames('time-picker', className)}
        id={controlId || `time-picker-${Math.random()}`}
      >
        <Dropdown
          usePortal
          onToggleOpen={this.onToggleOpen}
          disabled={disabled}
          setCloseFunction={this.setCloseFunction}
        >
          <Dropdown.Toggle className="mini-profile-button">
            <TextInput
              type="text"
              {...this.props}
              input={{
                value: inputValue,
                onChange: this.onChangeTextDate,
              }}
              {...addError}
              inputProps={{
                ref: this.setAnchorElement,
                onBlur: this.onBlur,
              }}
              {...inputProps}
              withLabel
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="time-picker-wrapper" anchorElement={anchorElement}>
            <div className="time-blocks">
              {this.renderBlock(1, 31, this.handleSelect('date'), this.isSelect('date'), this.setDayRef)}
              {this.renderBlock(1, 12, this.handleSelect('month'), this.isSelect('month'), this.setMonthRef, '', -1)}
              {this.renderBlock(1900, year - 1900 - marginDate, this.handleSelect('year'), this.isSelect('year'), this.setYearsRef, 'hour-block', 0, true)}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default compose(
  injectIntl,
)(DatePicker);
