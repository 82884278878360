import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './payment-log-page.scss';
import { Icons } from '../../../components/base/BootstrapComponents';
import {
  groupsOptions, defaultFilterValues, data, zonesOptions, defaultColumns,
} from '../moks';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import PaymentLogGrid from './PaymentLogGrid';
import PaymentLogPopUp from './PaymentLogPopUp';
import PaymentLogFilterForm from './PaymentLogFilterForm';
import SettingColumn from '../../../components/base/SettingColumn/SettingColumn';
import IconBox from '../../../components/common/IconBox';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';

export default function PaymentLogPage() {
  const [isPopup, setIsPopup] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [isOpenSettingColumn, setSettingColumn] = useState(false);
  const sessionLogColumn = getColumnsForTable(defaultColumns);
  const handlerClickRow = item => {
    setSelectedRow(item);
    setIsPopup(true);
  };

  const loadDataFromStorage = () => {
    const storage = JSON.parse(localStorage.getItem('columns-filter'));
    return storage ? storage.map(column => {
      const search = sessionLogColumn.find(e => e.dataField === column.name);
      return { ...search, ...column };
    }).sort((a, b) => a.top - b.top) : sessionLogColumn;
  };

  const [columns, setColumns] = useState(loadDataFromStorage());

  const {
    register, getValues, watch, setValue, reset,
  } = useForm({
    defaultValues: JSON.parse(localStorage.getItem('plp-filter')) || { ...defaultFilterValues },
  });

  watch();

  return (
    <div className="payment-log-page">
      <div className="plp-container">
        <div className="plp-inputs-box">
          <PageHeader header="Журнал платежей" />
          <div className="plp-inputs">
            <FieldWithAttributeDTO
              inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
              type="text"
              component={TextInput}
              dtoObject={{
                name: 'query',
                type: String,
                validators: [],
                labelKey: 'search',
              }}
              inputProps={{
                ...register('query', { maxLength: 30 }),
                value: getValues('query'),
                onChange: e => {
                  setValue('query', e.target.value);
                  localStorage.setItem('plp-filter', JSON.stringify({ ...getValues() }));
                },
              }}
              className="small no-border search-field "
            />
            <FieldWithAttributeDTO
              type="text"
              component={TextInput}
              placeholder="Номер сессии"
              dtoObject={{
                name: 'sessionNumber',
                type: String,
                validators: [],
                labelKey: 'plp.sessionNumber',
              }}
              inputProps={{
                ...register('sessionNumber', { maxLength: 30 }),
                value: getValues('sessionNumber'),
                onChange: e => {
                  setValue('sessionNumber', e.target.value);
                  localStorage.setItem('plp-filter', JSON.stringify({ ...getValues() }));
                },
              }}
              className="small no-border"
            />
            <FieldWithAttributeDTO
              type="text"
              dtoObject={{
                name: 'zones',
                type: String,
                validators: [],
                labelKey: 'zones',
              }}
              inputProps={{
                ...register('zones', { maxLength: 30 }),
                onChange: e => {
                  setValue('zones', e.target.value);
                  localStorage.setItem('plp-filter', JSON.stringify({ ...getValues() }));
                },
                value: Number(getValues('zones')) || '',
              }}
              component={CustomSelectInput}
              options={zonesOptions}
              className="select-field no-border"
            />
            <SeparateBox />
            <IconBox icon="archive" />
            <SeparateBox />
            <IconBox icon="export" />
            <SeparateBox />
            <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} />
            {isOpenFilter
              ? (
                <PaymentLogFilterForm
                  onHide={() => { setIsOpenFilter(false); }}
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  reset={reset}
                  defaultValues={defaultFilterValues}
                />
              )
              : null}
            {isOpenSettingColumn
              ? (
                <SettingColumn
                  onHide={() => { setSettingColumn(false); }}
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  reset={reset}
                  setColumns={setColumns}
                  columns={columns}
                  defaultValues={{}}
                />
              )
              : null}
          </div>
        </div>
        <PaymentLogGrid data={data} onClickByRow={handlerClickRow} columns={defaultColumns.filter(e => e.hidden !== false)} setSettingColumn={setSettingColumn} />
        { isPopup ? <PaymentLogPopUp onHide={() => setIsPopup(false)} selectedItem={selectedRow} columns={defaultColumns} /> : null }
      </div>
    </div>
  );
}
