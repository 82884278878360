import React, { useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import './session-log-page.scss';
import { Icons } from '../../../components/base/BootstrapComponents';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import {
  statusSessionOptions, defaultColumns, defaultFilterValues, data, zonesOptions,
} from '../moks';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import SessionLogGrid from './SessionLogGrid';
import SessionLogFilterForm from './SessionLogFilterForm';
import SettingColumn from '../../../components/base/SettingColumn/SettingColumn';
import IconBox from '../../../components/common/IconBox';
import CustomButton from '../../../components/base/Button/CustomButton';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import SessionLogStatus from './SessionLogStatus';

export default function SessionLogPage() {
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [isOpenSettingColumn, setSettingColumn] = useState(false);
  const sessionLogColumn = getColumnsForTable(defaultColumns);
  const handlerClickRow = item => {
    setSelectedRow(item);
  };

  const loadDataFromStorage = () => {
    const storage = JSON.parse(localStorage.getItem('columns-filter'));
    return storage ? storage.map(column => {
      const search = sessionLogColumn.find(e => e.dataField === column.name);
      return { ...search, ...column };
    }).sort((a, b) => a.top - b.top) : sessionLogColumn;
  };

  const [columns, setColumns] = useState(loadDataFromStorage());

  const {
    register, getValues, watch, setValue, reset,
  } = useForm({
    defaultValues: JSON.parse(localStorage.getItem('slp-filter')) || { ...defaultFilterValues },
  });

  watch();

  return (
    <div className="session-log-page">
      <div className="slp-container">
        <div className="slp-inputs-box">
          <div className={classNames('slp-title-page', { variant: selectedRow })}>
            {
                selectedRow ? (
                  <div className="path">
                    <span className="path-main" onClick={() => setSelectedRow(null)}>Журнал сессий</span> / <span>Статус сессии</span>
                  </div>
                ) : null
              }
            <PageHeader header={selectedRow ? `Статус сессии ${selectedRow.sessionNumber}` : 'Журнал сессий'} />
          </div>
          {
            !selectedRow ? (
              <div className="slp-inputs">
                <FieldWithAttributeDTO
                  inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
                  type="text"
                  component={TextInput}
                  dtoObject={{
                    name: 'query',
                    type: String,
                    validators: [],
                    labelKey: 'search',
                  }}
                  placeholder="Поиск"
                  inputProps={{
                    ...register('query', { maxLength: 30 }),
                    value: getValues('query'),
                    onChange: e => {
                      setValue('query', e.target.value);
                      localStorage.setItem('slp-filter', JSON.stringify({ ...getValues() }));
                    },
                  }}
                  className="small no-border search-field "
                />
                <FieldWithAttributeDTO
                  type="text"
                  component={TextInput}
                  dtoObject={{
                    name: 'sessionNumber',
                    type: String,
                    validators: [],
                    labelKey: 'slp.sessionNumber',
                  }}
                  placeholder="Номер сессии"
                  inputProps={{
                    ...register('sessionNumber', { maxLength: 30 }),
                    value: getValues('sessionNumber'),
                    onChange: e => {
                      setValue('sessionNumber', e.target.value);
                      localStorage.setItem('slp-filter', JSON.stringify({ ...getValues() }));
                    },
                  }}
                  className="small no-border"
                />
                <FieldWithAttributeDTO
                  type="text"
                  dtoObject={{
                    name: 'clientZone',
                    type: String,
                    validators: [],
                    labelKey: 'slp.clientZone',
                  }}
                  inputProps={{
                    ...register('clientZone', { maxLength: 30 }),
                    onChange: e => {
                      setValue('clientZone', e.target.value);
                      localStorage.setItem('slp-filter', JSON.stringify({ ...getValues() }));
                    },
                    value: Number(getValues('clientZone')) || '',
                  }}
                  placeholder="Зона клиента"
                  component={CustomSelectInput}
                  options={zonesOptions}
                  className="select-field no-border"
                />
                <FieldWithAttributeDTO
                  type="text"
                  dtoObject={{
                    name: 'sessionStatus',
                    type: String,
                    validators: [],
                    labelKey: 'slp.sessionStatus',
                  }}
                  placeholder="Статус сессии"
                  inputProps={{
                    ...register('sessionStatus', { maxLength: 30 }),
                    onChange: e => {
                      setValue('sessionStatus', e.target.value);
                      localStorage.setItem('slp-filter', JSON.stringify({ ...getValues() }));
                    },
                    value: Number(getValues('sessionStatus')) || '',
                  }}
                  component={CustomSelectInput}
                  options={statusSessionOptions}
                  className="select-field no-border"
                />
                <SeparateBox />
                <IconBox icon="export" />
                <SeparateBox />
                <IconBox icon="archive" />
                <SeparateBox />
                <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} />
                {isOpenFilter
                  ? (
                    <SessionLogFilterForm
                      onHide={() => { setIsOpenFilter(false); }}
                      setValue={setValue}
                      getValues={getValues}
                      register={register}
                      reset={reset}
                      defaultValues={defaultFilterValues}
                    />
                  )
                  : null}
                {isOpenSettingColumn
                  ? (
                    <SettingColumn
                      onHide={() => { setSettingColumn(false); }}
                      setValue={setValue}
                      getValues={getValues}
                      register={register}
                      reset={reset}
                      setColumns={setColumns}
                      columns={columns}
                      defaultValues={{}}
                    />
                  )
                  : null}
              </div>
            )
              : (
                <div className="slp-inputs">
                  <IconBox icon="document" />
                  <SeparateBox />
                  <IconBox icon="export" />
                  <SeparateBox />
                  <CustomButton text="Заблокировать" icon="lock-default" />
                </div>
              )
          }
        </div>
        { !selectedRow ? <SessionLogGrid data={data} onClickByRow={handlerClickRow} columns={defaultColumns.filter(e => e.hidden !== false)} setSettingColumn={setSettingColumn} /> : null}
        { selectedRow ? <SessionLogStatus selectedItem={selectedRow} columns={defaultColumns} /> : null }
      </div>
    </div>
  );
}
