import React from 'react';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import { clientStatusFormatter } from '../../../utils/formatters';
import SelectInput from '../../../components/fields/SelectInput/SelectInput';
import CustomButton from '../../../components/base/Button/CustomButton';
import { zonesOptions, typeClientOptions } from '../moks';
import ClientCardInfo from './ClientCardInfo';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';

export default function ClientForm(props) {
  const {
    setMode,
    setSelectedRow,
    selectedItem = null,
    mode,
  } = props;
  return (
    <div className="client-form">
      <span className="client-form-title">Основная информация</span>
      <div className="client-form-data">
        <div className="client-form-data-box custom-scroll-theme">
          <FieldWithAttributeDTO
            type="text"
            className="short"
            component={TextInputWithLabel}
            dtoObject={{
              name: 'clientId',
              type: String,
              validators: [],
              labelKey: 'client.clientId',
            }}
            inputProps={{
              value: '',
            }}
          />
          <FieldWithAttributeDTO
            type="text"
            className="short"
            dtoObject={{
              name: 'typeClient',
              type: String,
              validators: [],
              labelKey: 'client.typeClient',
            }}
            inputProps={{
              value: 0,
            }}
            withLabel
            addPlaceholder
            component={SelectInput}
            options={zonesOptions}
          />
          <FieldWithAttributeDTO
            type="text"
            component={TextInputWithLabel}
            addPlaceholder="Номер карты"
            dtoObject={{
              name: 'cardNumber',
              type: String,
              validators: [],
              labelKey: 'client.cardNumber',
            }}
            inputProps={{
              value: '',
            }}
          />
          <FieldWithAttributeDTO
            type="text"
            className="short"
            component={TextInputWithLabel}
            dtoObject={{
              name: 'governmentNumber',
              type: String,
              validators: [],
              labelKey: 'client.governmentNumber',
            }}
            inputProps={{
              value: '',
            }}
          />
          <FieldWithAttributeDTO
            type="text"
            className="short"
            dtoObject={{
              name: 'zone',
              type: String,
              validators: [],
              labelKey: 'client.zone',
            }}
            inputProps={{
              value: 0,
            }}
            withLabel
            addPlaceholder
            component={SelectInput}
            options={zonesOptions}
          />
          <FieldWithAttributeDTO
            type="text"
            dtoObject={{
              name: 'zone',
              type: String,
              validators: [],
              labelKey: 'client.zone',
            }}
            inputProps={{
              value: 0,
            }}
            withLabel
            addPlaceholder
            component={SelectInput}
            options={zonesOptions}
          />
          <FieldWithAttributeDTO
            type="text"
            className="short"
            component={TextInputWithLabel}
            dtoObject={{
              name: 'dateStart',
              type: String,
              validators: [],
              labelKey: 'client.dateStart',
            }}
            inputProps={{
              value: '',
            }}
          />
          <FieldWithAttributeDTO
            type="text"
            className="short"
            component={TextInputWithLabel}
            dtoObject={{
              name: 'dateEnd',
              type: String,
              validators: [],
              labelKey: 'client.dateEnd',
            }}
            inputProps={{
              value: '',
            }}
          />
          <FieldWithAttributeDTO
            type="text"
            dtoObject={{
              name: 'typeClient',
              type: String,
              validators: [],
              labelKey: 'client.typeClient',
            }}
            inputProps={{
              value: 0,
            }}
            renderOption={clientStatusFormatter}
            withLabel
            addPlaceholder
            component={CustomSelectInput}
            options={typeClientOptions}
          />
          <FieldWithAttributeDTO
            type="text"
            dtoObject={{
              name: 'client.clientStatus',
              type: String,
              validators: [],
              labelKey: 'client.clientStatus',
            }}
            inputProps={{
              value: 0,
            }}
            renderOption={clientStatusFormatter}
            withLabel
            addPlaceholder
            component={CustomSelectInput}
            options={typeClientOptions}
          />
          <FieldWithAttributeDTO
            type="text"
            dtoObject={{
              name: 'client.longed',
              type: String,
              validators: [],
              labelKey: 'client.longed',
            }}
            inputProps={{
              value: 0,
            }}
            // renderOption={clientStatusFormatter}
            withLabel
            addPlaceholder
            component={SelectInput}
            options={typeClientOptions}
          />
          <FieldWithAttributeDTO
            type="text"
            component={TextInputWithLabel}
            dtoObject={{
              name: 'feedback',
              type: String,
              validators: [],
              labelKey: 'client.feedback',
            }}
            inputProps={{
              value: '',
            }}
          />
          <div className="empty" />
        </div>
        { selectedItem && mode === 'edit' ? (
          <div className="client-form-data-card-wrapper">
            <ClientCardInfo selectedItem={selectedItem} />
            <div className="client-form-data-controls">
              <CustomButton isCancel text="Сгенерировать QR код" className="extend" />
              <CustomButton isCancel text="Распечатать" className="extend" />
            </div>
          </div>
        ) : null }
      </div>
      <div className="client-form-control">
        <CustomButton text="Сохранить" onClick={() => { setMode(false); setSelectedRow(null); }} />
        <CustomButton isCancel text="Закрыть" onClick={() => { setMode(false); setSelectedRow(null); }} />
      </div>
    </div>
  );
}
