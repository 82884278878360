import React from 'react';
import {
  MoreFormatter,
  statusFormatter,
  hostNameFormatter,
  dateOrDateTimeFormatter,
  eventFormatter,
} from '../../utils/formatters';

export const eventStatusOptions = [
  { value: 0, nameId: 'Статус события' },
  { value: 1, nameId: 'Выполнено' },
  { value: 2, nameId: 'Предупреждение' },
  { value: -1, nameId: 'Ошибка' }];

export const defaultFilterValues = {
  hostName: '',
  date: '',
  clientId: '',
  couponNumber: '',
  sessionNumber: '',
  status: '',
  event: '',
};

export const defaultSettingColumnValues = {
  checkBox: true,
  hostName: true,
  date: true,
  clientId: true,
  couponNumber: true,
  sessionNumber: true,
  status: true,
  event: true,
  setting: true,
};

export const updatePeriods = [
  { value: 0, name: 'Не обновлять', icon: 'update-period-0' },
  { value: 10, name: '10 секунд', icon: 'update-period-10' },
  { value: 30, name: '30 секунд', icon: 'update-period-30' },
  { value: 60, name: '1 минута', icon: 'update-period-60' },
  { value: 300, name: '5 минут', icon: 'update-period-300' },
];

export const defaultColumns = [
  {
    dataField: 'checkBox',
    text: 'Выбор строки',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-0 text-center column-relative',
    dataClassName: 'align-middle column-relative',
  },
  {
    dataField: 'hostName',
    id: 'hostName',
    text: 'Имя хоста (№)',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-1',
    formatter: hostNameFormatter,
    dataClassName: 'align-middle',
    disablePosition: false,
  },
  {
    dataField: 'date',
    text: 'Дата и время',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-2',
    formatter: date => dateOrDateTimeFormatter(date),
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'clientId',
    text: 'ИД клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-3',
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'couponNumber',
    text: 'Номер талона',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-4',
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'sessionNumber',
    text: 'Номер сессии',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-5',
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'status',
    text: 'Статус',
    disableSort: true,
    headerClassName: 'column-6',
    dataClassName: 'align-middle text-center',
    formatter: statusFormatter,
    hidden: true,
    disablePosition: false,
  },
  {
    dataField: 'event',
    text: 'Событие',
    disableSort: true,
    formatter: eventFormatter,
    dataClassName: 'align-middle',
    headerClassName: 'column-7',
    hidden: true,
    disablePosition: false,
  },
];

export const data = [
  {
    id: '23fd',
    hostName: 'Въездная группа 37',
    date: '2023-12-03T10:15:30+03:00',
    clientId: 'AH1235FG',
    couponNumber: '1212323',
    sessionNumber: '1212323',
    status: 2,
    event: 'Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от   Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя',
  },
  {
    id: '2w3fd',
    hostName: 'Въездная группа 93',
    date: '2024-07-03T10:15:30+03:00',
    clientId: 'KO1235FG',
    couponNumber: '1212323',
    sessionNumber: '1212323',
    status: 1,
    event: 'Нет сигнала от RFID-считывателя',
  },
  {
    id: '23fdd',
    hostName: 'Въездная группа 63',
    date: '2023-12-03T10:15:30+03:00',
    clientId: 'LK1235FG',
    couponNumber: '1212323',
    sessionNumber: '1212323',
    status: -1,
    event: 'Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от   Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя',
  },
  {
    id: '23fdu7d',
    hostName: 'Въездная группа 83',
    date: '2023-12-03T10:15:30+03:00',
    clientId: 'NJ1235FG',
    couponNumber: '1212323',
    sessionNumber: '1212323',
    status: -1,
    event: 'Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от   Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя',
  },
  {
    id: '2ug83fdd',
    hostName: 'Въездная группа 43',
    date: '2023-12-03T10:15:30+03:00',
    clientId: 'KI1235FG',
    couponNumber: '1212323',
    sessionNumber: '1212323',
    status: 0,
    event: 'Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от   Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя',
  },
  {
    id: '23fhvf6dd',
    hostName: 'Въездная группа 31',
    date: '2023-12-03T10:15:30+03:00',
    clientId: 'GFGHB235FG',
    couponNumber: '1276323',
    sessionNumber: '656',
    status: 1,
    event: 'Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от   Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя',
  },
  {
    id: '23f88dd',
    hostName: 'Въездная группа 34',
    date: '2023-12-03T10:15:30+03:00',
    clientId: 'KF1235FG',
    couponNumber: '1212323',
    sessionNumber: '1212323',
    status: 1,
    event: 'Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от   Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя',
  },
  {
    id: '2389yfdd',
    hostName: 'Въездная группа 12',
    date: '2023-12-03T10:15:30+03:00',
    clientId: 'KF1235FG',
    couponNumber: '1212323',
    sessionNumber: '1212323',
    status: 1,
    event: 'Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от   Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от  RFID-считывателя Нет сигнала от RFID-считывателя Нет сигнала от RFID-считывателя',
  },
];
