import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { intlShape } from '../components/base/IntlProviderWrapper';
import { Button, Icons } from '../components/base/BootstrapComponents';
import GovermentNumber from '../components/base/GovermentNumber/GovermentNumber';
import TooltipWithPortal from '../components/base/Tooltip/TooltipWithPortal';
import VisualPark from '../components/common/VisualPark';
import Dropdown from '../components/base/Dropdown/Dropdown';

export function HostNameAndStatusFormatter({ item, isComponentBase }) {
  const anchorElement = useRef(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="host">
      <div className="host-assembly">
        { isComponentBase ? (
          <div className="working-state-helper">
            <Dropdown
              usePortal
              customControl
              customOpen={isOpen}
              customHandleClose={() => { setOpen(false); }}
            >
              <Dropdown.Toggle>
                <div
                  className={classNames('tooltip-content')}
                  ref={anchorElement}
                >
                  <div className="host-assembly-icon" ref={anchorElement}>
                    <Icons width={20} height={20} customIcon icon="assembly" className="button-icons" />
                    <div className="field-click-ext" onClick={e => { e.stopPropagation(); setOpen(true); }} />
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={classNames('portal-tooltip-wrapper wsh-portal-tooltip-wrapper')}
                anchorElement={anchorElement}
                classNamePortal="portal-tooltip wsh-portal"
                useResizeContent={false}
              >
                <div
                  className="portal-tooltip-popup"
                >
                  <div className="wsh-info-tooltip">
                    <div className="wsh-info-title">
                      Компонентная база
                      <Icons icon="close" customIcon className="close" onClick={e => { e.stopPropagation(); setOpen(false); }} />
                    </div>
                    <table className="wsh-info-table">
                      <tbody>
                        {item.equipments.length && item.equipments.map((item, key) => (
                          <tr className="wsh-info-table-row" key={key}>
                            <td className="wsh-info-table-cell wsh-key">{item.name}</td>
                            <td className="wsh-info-table-cell wsh-value">{item.model || 'Нет данных'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : null }
      </div>
      <span className="host-name">
        <div className="host-status-circle" />
        {item.host}
      </span>
      <span className="host-type">{item.type}</span>
    </div>
  );
}

export const equipmentFormatter = item => (
  <div className="equipments-group">
    {item.map(icon => (
      <div className="equipments-group-item" key={icon}>
        <Icons width={32} height={32} customIcon icon={icon.name} className="button-icons" />
        <div className="equipments-group-item-status">
          { icon.status === 1 ? null : <Icons width={17} height={17} customIcon icon={icon.status ? 'error-circle' : 'warning-circle'} className="button-icons" />}
        </div>
      </div>
    ))}
  </div>
);

export const eventFormatter = item => (
  <div className="event-formatter">
    <TooltipWithPortal classNameDropDown="event-formatter-portal-tooltip" content={item} />
  </div>
);

export const historyStatusFormatter = status => (
  <div className="history-status-formatter">
    <div className={classNames('history-status-formatter-info', { isError: status === 0 }, { isWait: status === -1 })}>
      { status === 1 ? 'Выполнена' : (status ? 'Ожидает' : 'Ошибка') }
    </div>
  </div>
);

export const visualFormatter = item => (
  <VisualPark type={item.type} />
);

export const govermentNumberFormatter = item => (
  <GovermentNumber
    govermentNumber={{
      number: item.number,
      char: item.char,
      charSecond: item.charSecond,
      region: item.region,
      country: item.country,
      type: item.type,
      moto: item.moto,
    }}
  />
);

export const govermentNumberTextFormatter = item => (
  <div>
    {item.number}
  </div>
);

export const logFormatter = (_, item) => {
  const { date, data, sum } = _;
  return (
    <div className="log">
      <span className="log-time">{ date }</span>
      <span className="log-data">{ data }</span>
      { item.variant === 1 ? <span className="log-sum">Сумма: {sum}</span> : null }
    </div>
  );
};

export const controlBoxFormatter = item => (
  <div className="control-box-formatter">
    {item.map(icon => (
      <div className="control-box-button" key={icon} onClick={e => e.stopPropagation()}>
        <Icons width={24} height={24} customIcon icon={icon} className="button-icons" />
        <div className="expanded-click-zone" onClick={e => { e.stopPropagation(); }} />
      </div>
    ))}
  </div>
);

export const optionClassFormatter = item => {
  let status = item;
  if (typeof item === 'object') {
    status = item.value;
  }
  if (typeof item !== 'undefined') {
    if (+status === 1) {
      return 'good-state';
    }
    if (+status === 0) {
      return 'warning-state';
    }
    if (+status === -1) {
      return 'error-state';
    }
  }
  return 'unknown-state';
};

export const hostNameFormatter = item => (
  <span className="host-name-formatter">{item}</span>
);

export const sessionNumberFormatter = item => (
  <span className="session-number-formatter">{item}</span>
);

export const clientTypeFormatter = item => (
  <div className="client-type-formatter">
    {(/\d/).test(item)
      ? (
        <div className="client-type-formatter-box">
          {item}
        </div>
      ) : item }
  </div>
);

export const clientStatusFormatter = item => (
  <div className={classNames('client-status-formatter', { warning: item === 2, error: item === -1 })}>
    <div className={classNames('client-status-formatter-box', { warning: item === 2, error: item === -1 })}>
      { item === 1 ? 'Активен' : item === -1 ? 'Заблокирован' : 'Активен за территорией' }
    </div>
  </div>
);

export const serviceStatusFormatter = item => (
  <div className={classNames('service-status-formatter', { warning: item === 2, error: item === -1 })}>
    <div className={classNames('service-status-formatter-box', { warning: item === 2, error: item === -1 })}>
      { item === 1 ? 'Активна' : item === -1 ? 'Деактивирован' : 'Стоп' }
    </div>
  </div>
);

export const sessionStatusFormatter = item => (
  <div className="session-status-formatter">
    <div className={classNames('session-status-formatter-box', { sucssesful: item === 1 })}>
      { item === 1 ? 'Активна' : 'Завершена' }
    </div>
  </div>
);

export const statusFormatter = status => (
  <div>
    { status === 1 ? <Icons width={24} height={24} customIcon icon="successful" className="button-icons" /> : <Icons width={24} height={24} customIcon icon={status === -1 ? 'error' : 'warning'} className="button-icons" />}
  </div>
);

export const statusNotificationFormatter = status => (
  <div className={classNames('status-notification-formatter', { warning: status === 2, error: status === -1 })}>
    { status === 1 ? <Icons width={20} height={20} customIcon icon="successful" className="button-icons" /> : <Icons width={20} height={20} customIcon icon={status === -1 ? 'error' : 'warning'} className="button-icons" />}
    <span className="status-notification-formatter-text"> { status === 1 ? 'Выполнено' : status === 2 ? 'Предупреждение' : 'Ошибка'}</span>
  </div>
);

export const dateFormatter = a => {
  const {
    formatDate,
  } = intlShape;
  if (!a) {
    return '';
  }
  if (typeof a === 'string') {
    return a;
  }
  return formatDate(a, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
};

export const timeFormatter = a => {
  const {
    formatDate,
  } = intlShape;
  if (!a) {
    return '';
  }
  return formatDate(a, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};

export const timeHMFormatter = a => {
  const {
    formatDate,
  } = intlShape;
  if (!a) {
    return '';
  }
  return formatDate(a, {
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const dateTimeFormatter = a => {
  const {
    formatDate,
  } = intlShape;
  if (!a) {
    return '';
  }
  return formatDate(a, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).replace(',', ' ');
};

export function dateOrDateTimeFormatter(date, onlyHour = false, onlyDate = false) {
  const currentDay = moment().tz('Europe/Moscow').day();
  const d = moment(date).tz('Europe/Moscow');
  const dateDay = moment(d).day();
  if (onlyHour) {
    return d ? d.format('HH:mm') : null;
  }
  if (onlyDate) {
    return d ? d.format('DD.MM.YY') : null;
  }
  return d ? d.format(dateDay === currentDay ? 'HH:mm' : 'DD.MM.YY HH:mm') : null;
}

export function MoreFormatter() {
  return (
    <TooltipWithPortal
      showByClick
      content={<Icons customIcon icon="dot" className="more-vert" />}
      classNamePortal="mpf-more-actions"
      tooltipContent={(
        <div className="hosts-more-wrapper">
          <Button variant="link" className="w-100 hosts-more-button">
            <div className="hosts-more-button-icon">
              <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
            </div>
            Перезапуск ПО
          </Button>
          <Button variant="link" className="w-100 hosts-more-button">
            <div className="hosts-more-button-icon">
              <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
            </div>
            Перезагрузка модуля оплаты
          </Button>
          <Button variant="link" className="w-100 hosts-more-button">
            <div className="hosts-more-button-icon">
              <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
            </div>
            Антизатор
          </Button>
          <Button variant="link" className="w-100 hosts-more-button">
            <div className="hosts-more-button-icon">
              <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
            </div>
            dummymode
          </Button>
          <Button variant="link" className="w-100 hosts-more-button">
            <div className="hosts-more-button-icon">
              <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
            </div>
            Заблокировать
          </Button>
          <Button variant="link" className="w-100 hosts-more-button">
            <div className="hosts-more-button-icon">
              <Icons width={18} height={14} customIcon icon="reorder" className="button-icons" />
            </div>
            Перезагрузка модуля распознавания
          </Button>
        </div>
      )}
    />
  );
}
