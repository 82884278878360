import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import '../../EventLog/components/event-log-page.scss';
import { data as eventLogData, defaultColumns as eventLogColumns } from '../../EventLog/moks';
import { data as paymentLogData, defaultColumns as paymentLogColumns } from '../../PaymentLog/moks';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import EventLogGrid from '../../EventLog/components/EventLogGrid';
import SessionLogEditPopUp from './SessionLogEditPopUp';
import PaymentLogGrid from '../../PaymentLog/components/PaymentLogGrid';
import defaultCarImg from '../../../theme/img/default-car.png';
import { Icons } from '../../../components/base/BootstrapComponents';

export default function SessionLogStatus(props) {
  const basicKeys = ['sessionStatus', 'clientId', 'clientZone', 'clientType', 'governmentNumber'];
  const [tab, setTab] = useState(1);
  const [isPopUp, setIsPopUp] = useState(false);
  const { selectedItem, columns } = props;
  const navigate = useNavigate();

  return (
    <div className="slp-status">
      <div className="slp-basic-information">
        <div className="slp-basic-img" alt="avatar" style={{ background: `URL(${selectedItem.img || defaultCarImg})`, backgroundSize: 'cover' }} />
        <span className="slp-basic-information-title">Основная информация</span>
        <div className="slp-basic-information-box">
          {getColumnsForTable(columns, basicKeys).map((column, index) => (
            <div className="slp-basic-information-box-item" key={index}>
              <div className="slp-basic-information-box-item-title">{column.text}</div>
              <div className="slp-basic-information-box-item-content">
                {column.formatter ? column.formatter(selectedItem[column.dataField]) : selectedItem[column.dataField]}
                {column.dataField === 'governmentNumber' ? (
                  <div className="slp-basic-control-button" onClick={() => setIsPopUp(true)}>
                    <Icons width={24} height={24} customIcon icon="pencile" className="button-icons" />
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="slp-status-table-box">
        <div className="slp-status-tab-menu">
          <div className={classNames('slp-status-tab-item', { selected: tab === 1 })} onClick={() => setTab(1)}>Журнал событий</div>
          <div className={classNames('slp-status-tab-item', { selected: tab === 2 })} onClick={() => setTab(2)}>Журнал платежей</div>
        </div>
        <div className="slp-status-tables-container">
          <div className="slp-status-tables-container-title">
            <div className="slp-status-title">{tab === 1 ? 'Журнал событий' : 'Журнал платежей'}</div>
            <div className="slp-status-show-table" onClick={() => navigate(tab === 1 ? '/event-log' : '/payment-log')}>Показать всё</div>
          </div>
          { tab === 1 ? <EventLogGrid data={eventLogData} columns={getColumnsForTable(eventLogColumns, ['hostName', 'date', 'status', 'event', 'setting'])} withPagination={false} /> : null }
          { tab === 2 ? <PaymentLogGrid data={paymentLogData} columns={getColumnsForTable(paymentLogColumns, ['hostName', 'date', 'sum', 'rrn', 'cardNumber', 'paymentMethod', 'paymentType', 'setting'])} withPagination={false} /> : null }
          { isPopUp ? <SessionLogEditPopUp onHide={() => setIsPopUp(false)} item={selectedItem} /> : null }
        </div>
      </div>
    </div>
  );
}
