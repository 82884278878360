import React from 'react';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import ClientCardInfo from './ClientCardInfo';
import { defaultColumns } from '../moks';
import { Icons } from '../../../components/base/BootstrapComponents';

export default function ClientCard(props) {
  const { selectedItem, setIsPopUp } = props;
  return (
    <div className="client-card">
      <span className="client-card-title">Основная информация</span>
      <div className="client-card-box">
        <div className="client-card-basic-information">
          <div className="client-card-basic-information-box">
            {getColumnsForTable(defaultColumns, ['clientStatus', 'clientId', 'typeClient', 'cardNumber', 'govermentNumber', 'zone', 'feedback', 'dateOfUse']).map((column, index) => (
              <div className="client-card-basic-information-box-item" key={index}>
                <div className="client-card-basic-information-box-item-title">{column.text}</div>
                <div className="client-card-basic-information-box-item-content">
                  {column.formatter ? column.formatter(selectedItem[column.dataField]) : selectedItem[column.dataField]}
                  {column.dataField === 'govermentNumber' ? (
                    <div className="client-card-basic-control-button" onClick={() => setIsPopUp(true)}>
                      <Icons width={24} height={24} customIcon icon="pencile" className="button-icons" />
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
        <ClientCardInfo selectedItem={selectedItem} />
      </div>
    </div>
  );
}
