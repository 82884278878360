import React, { useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import './hosts-page.scss';
import { Icons } from '../../../components/base/BootstrapComponents';
import {
  groupsOptions, hostList, tabOptions, zonesOptions, defaultFilterValues,
} from './moks';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import TabMenu from '../../../components/base/TabMenu/TabMenu';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import HostGrid from './HostsGrid';
import HostsFilterForm from './HostsFilterForm';
import SettingColumn from '../../../components/base/SettingColumn/SettingColumn';
import IconBox from '../../../components/common/IconBox';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import HostStatus from './HostStatus';
import YandexMap from '../../../components/base/Map/YandexMap';
import mapImgMock from '../../../theme/img/char-mock.png';

export default function HostsPage() {
  const [selectedTab, isSelectedTab] = useState(1);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [isOpenSettingColumn, setSettingColumn] = useState(false);

  const saveTab = item => {
    isSelectedTab(item.value);
  };

  // const loadDataFromStorage = () => {
  //   const storage = JSON.parse(localStorage.getItem('columns-filter'));
  //   return storage ? storage.map(column => {
  //     const search = hostColumn.find(e => e.dataField === column.name);
  //     return { ...search, ...column };
  //   }).sort((a, b) => a.top - b.top) : hostColumn;
  // };

  const {
    register, getValues, watch, setValue, reset,
  } = useForm({
    defaultValues: JSON.parse(localStorage.getItem('host-filter')) || { ...defaultFilterValues },
  });

  const handlerClickRow = item => {
    setSelectedRow(item);
  };

  watch();

  return (
    <div className="hosts-page">
      <div className="hosts-wrapper">
        <div className="hosts-inputs-box">
          <div className={classNames('hosts-info', { variant: selectedRow })}>
            {
                selectedRow ? (
                  <div className="path">
                    <span className="path-main" onClick={() => setSelectedRow(null)}>Мониторинг хостов</span> / <span>Статус хоста</span>
                  </div>
                ) : null
              }
            <PageHeader header={selectedRow ? 'Статус хоста' : 'Мониторинг хостов'} />
            { !selectedRow ? (
              <TabMenu
                selectedTab={selectedTab}
                options={tabOptions}
                onSelectOptions={item => { saveTab(item); }}
              />
            ) : null }
          </div>
          {
            !selectedRow ? (
              <div className="hosts-inputs">
                <FieldWithAttributeDTO
                  inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
                  type="text"
                  component={TextInput}
                  dtoObject={{
                    name: 'query',
                    type: String,
                    validators: [],
                    labelKey: 'search',
                  }}
                  inputProps={{
                    ...register('query', { maxLength: 30 }),
                    value: getValues('query'),
                    onChange: e => {
                      setValue('query', e.target.value);
                      localStorage.setItem('columns-filter-order', JSON.stringify({ ...getValues() }));
                    },
                  }}
                  className="small no-border search-field "
                />
                <FieldWithAttributeDTO
                  type="text"
                  dtoObject={{
                    name: 'zones',
                    type: String,
                    validators: [],
                    labelKey: 'zones',
                  }}
                  inputProps={{
                    ...register('zones', { maxLength: 30 }),
                    onChange: e => {
                      setValue('zones', e.target.value);
                      localStorage.setItem('columns-filter-order', JSON.stringify({ ...getValues() }));
                    },
                    value: Number(getValues('zones')) || '',
                  }}
                  component={CustomSelectInput}
                  options={zonesOptions}
                  className="no-border select-field"
                />
                <FieldWithAttributeDTO
                  type="text"
                  dtoObject={{
                    name: 'group',
                    type: String,
                    validators: [],
                    labelKey: 'group',
                  }}
                  inputProps={{
                    ...register('group', { maxLength: 30 }),
                    onChange: e => {
                      setValue('group', e.target.value);
                      localStorage.setItem('columns-filter-order', JSON.stringify({ ...getValues() }));
                    },
                    value: Number(getValues('group')) || '',
                  }}
                  component={CustomSelectInput}
                  options={groupsOptions}
                  className="no-border select-field"
                />
                <SeparateBox />
                <IconBox icon="export" />
                <SeparateBox />
                <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} />
                {isOpenFilter
                  ? (
                    <HostsFilterForm
                      onHide={() => { setIsOpenFilter(false); }}
                      setValue={setValue}
                      getValues={getValues}
                      register={register}
                      reset={reset}
                      defaultValues={defaultFilterValues}
                    />
                  )
                  : null}
              </div>
            )
              : null
          }
        </div>
        { selectedTab === 1 && !selectedRow ? <HostGrid data={hostList} onClickByRow={handlerClickRow} /> : null }
        { selectedTab === 1 && selectedRow ? <HostStatus hostList={selectedRow} /> : null }
        { selectedTab === 2 ? <YandexMap zones={[]} selectedZone={{}} setZones={() => {}} /> : null }
      </div>
    </div>
  );
}
