import React from 'react';
import classNames from 'classnames';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import { Icons } from '../../../components/base/BootstrapComponents';
import CustomButton from '../../../components/base/Button/CustomButton';
import ClientCard from './ClientCard';

export default function ClientDetailsPopUp(props) {
  const { onHide, setMode, selectedItem } = props;
  return (
    <Dialog
      className={classNames('client-popup-details')}
      onClose={() => { onHide(); }}
      size="xl"
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">{`Просмотр карточки клиента ${selectedItem.clientId} (ИД)`}</div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={onHide} />
            </div>
          </>
        )}
      />
      <DialogContent className="hff-content">
        <ClientCard selectedItem={selectedItem} />
      </DialogContent>
      <DialogFooter className="hff-footer">
        <CustomButton isCancel text="QR код" className="small" onClick={() => { onHide(); }} />
        <CustomButton isCancel text="Печать" className="small" onClick={() => { onHide(); }} />
        <CustomButton text="Редактировать" onClick={() => { onHide(); setMode('edit'); }} />
      </DialogFooter>
    </Dialog>
  );
}
