import React, { useState } from 'react';
import classNames from 'classnames';
import Grid from '../../../components/base/Grid/Grid';
import Pagination from '../../../components/base/Pagination/Pagination';
import { Icons } from '../../../components/base/BootstrapComponents';
import SettingColumn from '../../../components/base/SettingColumn/SettingColumn';

import {
  equipmentFormatter,
  visualFormatter,
  MoreFormatter,
  logFormatter,
  controlBoxFormatter,
  govermentNumberFormatter,
  HostNameAndStatusFormatter,
} from '../../../utils/formatters';
import CheckBoxInput from '../../../components/fields/CheckBoxInput/CheckBoxInput';
import CustomizedGrid from '../../../components/base/Grid/CustomizedGrid';

export default function HostGrid(props) {
  const defaultColumns = [
    {
      dataField: 'host',
      text: 'Объект',
      formatter: (_, a) => (<HostNameAndStatusFormatter item={a} isComponentBase />),
      disableSort: true,
      hidden: true,
      headerClassName: 'column-0',
    },
    {
      dataField: 'equipments',
      text: 'Периферийные модули',
      formatter: equipmentFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-1',
      hidden: true,
    },
    {
      dataField: 'visual',
      text: 'Визуализация',
      formatter: visualFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-2',
      hidden: true,
    },
    {
      dataField: 'govermentNumber',
      text: 'ГРЗ',
      formatter: govermentNumberFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-3',
      hidden: true,
    },
    {
      dataField: 'log',
      text: 'Последние данные',
      formatter: logFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'column-4',
      hidden: true,
    },
    {
      dataField: 'control',
      text: 'Доступные действия',
      formatter: controlBoxFormatter,
      disableSort: true,
      dataClassName: 'align-middle',
      headerClassName: 'align-middle column-5',
      hidden: true,
    },
  ];

  const {
    data,
    hostListMeta,
    handleLoadingHostList,
    onClickByRow,
    setData,
    columns = defaultColumns,
    withPagination = true,
  } = props;

  return (
    <div className="host-table g-0">
      <div className="frame">
        <div className="sticky-table-wrapper custom-scroll-theme">
          <CustomizedGrid className="host-grid" data={data} columns={columns} name="session-list" onClickByRow={onClickByRow} settingsFormatter={MoreFormatter} />
        </div>
      </div>
      { withPagination ? <Pagination meta={hostListMeta} list={data} handleChangeMeta={handleLoadingHostList} /> : null }
    </div>
  );
}
