import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  Dialog, DialogContent, DialogFooter, DialogTitle,
} from '../../../components/base/Dialog/Dialog';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import { Icons, Button } from '../../../components/base/BootstrapComponents';
import TextInputWithLabel from '../../../components/fields/TextInput/TextInputWithLabel';
import SelectInput from '../../../components/fields/SelectInput/SelectInput';

import { groupsOptions } from '../moks';

export default function ClientFilterForm(props) {
  const {
    onHide, getValues, setValue, register, reset, defaultValues,
  } = props;
  const [scroll, setScroll] = useState(null);
  useEffect(() => {
    const a = new window.IScroll('#filter-scroll-wrapper', {
      scrollbars: true,
      mouseWheel: true,
      preventDefault: false,
      interactiveScrollbars: true,
    });
    setScroll(a);
  }, []);

  const clear = () => {
    reset({ ...getValues(), ...defaultValues });
  };

  const save = () => {
    localStorage.setItem('client-filter', JSON.stringify({ ...getValues() }));
    onHide();
  };

  return (
    <Dialog
      className={classNames('full-filter')}
      onClose={save}
      open
    >
      <DialogTitle
        className="hff-title"
        title={(
          <>
            <div className="hff-title-text f2">Фильтры</div>
            <div className="hff-close-wrapper">
              <Icons width={14} height={14} customIcon icon="clear" className="hff-close" onClick={onHide} />
            </div>
          </>
      )}
      />
      <DialogContent className="hff-content">
        <div className="scroll-wrapper filter-scroll-wrapper" id="filter-scroll-wrapper">
          <div className="scroll">
            <form>
              <FieldWithAttributeDTO
                type="text"
                component={TextInputWithLabel}
                placeholder="Начните печатать для поиска"
                dtoObject={{
                  name: 'clientId',
                  type: String,
                  validators: [],
                  labelKey: 'client.clientId',
                }}
                inputProps={{
                  value: getValues('clientId'),
                  ...register('clientId'),
                }}
              />
              <FieldWithAttributeDTO
                type="text"
                component={TextInputWithLabel}
                placeholder="Введите номер сессии"
                dtoObject={{
                  name: 'sessionNumber',
                  type: String,
                  validators: [],
                  labelKey: 'client.sessionNumber',
                }}
                className="filter-margin"
                inputProps={{
                  value: getValues('sessionNumber'),
                  ...register('sessionNumber'),
                }}
              />
              <FieldWithAttributeDTO
                type="text"
                dtoObject={{
                  name: 'hostName',
                  type: String,
                  validators: [],
                  labelKey: 'client.hostName',
                }}
                inputProps={{
                  value: getValues('hostName'),
                  ...register('hostName'),
                }}
                component={TextInputWithLabel}
                options={groupsOptions}
                className="filter-margin"
                withLabel
                addPlaceholder
              />
              <FieldWithAttributeDTO
                type="text"
                dtoObject={{
                  name: 'paymentMethod',
                  type: String,
                  validators: [],
                  labelKey: 'client.paymentMethod',
                }}
                inputProps={{
                  ...register('typeClient', { maxLength: 30 }),
                  onChange: e => {
                    setValue('typeClient', e.target.value);
                  },
                  value: Number(getValues('typeClient')),
                }}
                withLabel
                addPlaceholder
                component={SelectInput}
                options={groupsOptions}
                className="filter-margin"
              />
              <FieldWithAttributeDTO
                type="text"
                dtoObject={{
                  name: 'paymentType',
                  type: String,
                  validators: [],
                  labelKey: 'client.paymentType',
                }}
                inputProps={{
                  ...register('paymentType', { maxLength: 30 }),
                  onChange: e => {
                    setValue('paymentType', e.target.value);
                  },
                  value: Number(getValues('paymentType')),
                }}
                withLabel
                addPlaceholder
                component={SelectInput}
                options={groupsOptions}
                className="filter-margin"
              />
              <FieldWithAttributeDTO
                type="text"
                component={TextInputWithLabel}
                dtoObject={{
                  name: 'cardNumber',
                  type: String,
                  validators: [],
                  labelKey: 'client.cardNumber',
                }}
                className="filter-margin"
                inputProps={{
                  value: getValues('cardNumber'),
                  ...register('cardNumber'),
                }}
              />
              <FieldWithAttributeDTO
                type="text"
                component={TextInputWithLabel}
                placeholder="Начните печатать для поиска"
                dtoObject={{
                  name: 'rrn',
                  type: String,
                  validators: [],
                  labelKey: 'client.rrn',
                }}
                className="filter-margin"
                inputProps={{
                  value: getValues('rrn'),
                  ...register('rrn'),
                }}
              />
              <FieldWithAttributeDTO
                type="text"
                component={TextInputWithLabel}
                dtoObject={{
                  name: 'authorizationCode',
                  type: String,
                  validators: [],
                  labelKey: 'client.authorizationCode',
                }}
                className="filter-margin"
                inputProps={{
                  value: getValues('authorizationCode'),
                  ...register('authorizationCode'),
                }}
              />
            </form>
          </div>
        </div>
      </DialogContent>
      <DialogFooter className="hff-footer">
        <div className="hff-footer-text">Найдено 6 хостов</div>
        {/* {meta && meta.total ? meta.total : 0} */}
        <div className="hff-footer-buttons">
          <Button className="hff-clear-show" onClick={save}>Показать</Button>
          <Button className="hff-clear-data" variant="secondary" onClick={clear}>Сбросить все</Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}
