import React from 'react';
import {
  MoreFormatter,
  sessionNumberFormatter,
  dateOrDateTimeFormatter,
} from '../../utils/formatters';
import { Icons } from '../../components/base/BootstrapComponents';

export const zonesOptions = [
  { value: 0, name: 'Зона клиента' },
  { value: 1, name: 'Общая зона' },
  { value: 2, name: 'VIP зона' },
  { value: 3, name: 'Зона перрона убытия' },
  { value: 4, name: 'Долговременная зона' },
  { value: 5, name: 'Зона перрона прибытия' }];

export const defaultFilterValues = {
  hostName: '',
  date: '',
  clientId: '',
  sum: '',
  rrn: '',
  authorizationCode: '',
  cardNumber: '',
  sessionNumber: '',
  paymentMethod: '',
  paymentType: '',
  zones: '',
};

export const groupsOptions = [
  { value: 0, name: 'По умолчанию' },
  { value: 1, name: 'Группа 1' },
  { value: 2, name: 'Группа 2' },
  { value: 3, name: 'Группа 3' },
  { value: 4, name: 'Группа 4' },
  { value: 5, name: 'Группа 5' }];

export const defaultColumns = [
  {
    dataField: 'checkBox',
    disableSort: true,
    text: 'Выбор строки',
    hidden: true,
    headerClassName: 'column-0 text-center column-relative',
    dataClassName: 'align-middle column-relative',
  },
  {
    dataField: 'clientId',
    text: 'ИД клиента',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-1',
    hidden: true,
  },
  {
    dataField: 'sessionNumber',
    text: 'Номер сессии',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-2',
    formatter: sessionNumberFormatter,
    dataClassName: 'align-middle',
  },
  {
    dataField: 'hostName',
    text: 'Имя хоста',
    disableSort: true,
    hidden: true,
    headerClassName: 'column-3',
    dataClassName: 'align-middle',
  },
  {
    dataField: 'date',
    text: 'Дата, время оплаты',
    disableSort: true,
    dataClassName: 'align-middle',
    formatter: date => dateOrDateTimeFormatter(date),
    headerClassName: 'column-4',
    hidden: true,
  },
  {
    dataField: 'sum',
    text: 'Сумма оплаты, руб',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-5',
    hidden: true,
  },
  {
    dataField: 'paymentMethod',
    text: 'Способ оплаты',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-6',
    hidden: true,
  },
  {
    dataField: 'paymentType',
    text: 'Тип платежа',
    disableSort: true,
    headerClassName: 'column-7',
    dataClassName: 'align-middle',
    hidden: true,
  },
  {
    dataField: 'cardNumber',
    text: 'Номер карты',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-8',
    hidden: true,
  },
  {
    dataField: 'rrn',
    text: 'RRN',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-9',
    hidden: true,
  },
  {
    dataField: 'authorizationCode',
    text: 'Код авторизации',
    disableSort: true,
    dataClassName: 'align-middle',
    headerClassName: 'column-10',
    hidden: true,
  },
];

export const data = [
  {
    id: '23fdd3',
    hostName: 'Въездная группа 93',
    date: '2023-12-03T10:15:30+03:00',
    clientId: 'KF1235FG',
    sum: '350,00',
    rrn: '411485063086',
    authorizationCode: '321791',
    cardNumber: '220220******6513',
    sessionNumber: '1212323',
    paymentMethod: 'Банковская карта',
    paymentType: 'Парковочная сессия',
    setting: {},
  },
  {
    id: '243fdd',
    hostName: 'Въездная группа 13',
    date: '2023-12-03T10:15:30+03:00',
    clientId: 'KF1235FG',
    sum: '350,00',
    rrn: '411485063086',
    authorizationCode: '321791',
    cardNumber: '220220******6513',
    sessionNumber: '1212323',
    paymentMethod: 'Банковская карта',
    paymentType: 'Парковочная сессия',
    setting: {},
  },
  {
    id: '2s3fdd',
    hostName: 'Въездная группа 43',
    date: '2024-07-03T10:15:30+03:00',
    clientId: 'KF1235FG',
    sum: '350,00',
    rrn: '411485063086',
    authorizationCode: '321791',
    cardNumber: '220220******6513',
    sessionNumber: '1212323',
    paymentMethod: 'Банковская карта',
    paymentType: 'Парковочная сессия',
    setting: {},
  },
  {
    id: '2s3fdd',
    hostName: 'Въездная группа 123',
    date: '2024-07-03T10:15:30+03:00',
    clientId: 'KF1235FG',
    sum: '350,00',
    rrn: '411485063086',
    authorizationCode: '321791',
    cardNumber: '220220******6513',
    sessionNumber: '1212323',
    paymentMethod: 'Банковская карта',
    paymentType: 'Парковочная сессия',
    setting: {},
  },
  {
    id: '2s3fdd',
    hostName: 'Въездная группа 33',
    date: '2024-07-03T10:15:30+03:00',
    clientId: 'KF1235FG',
    sum: '350,00',
    rrn: '411485063086',
    authorizationCode: '321791',
    cardNumber: '220220******6513',
    sessionNumber: '1212323',
    paymentMethod: 'Банковская карта',
    paymentType: 'Парковочная сессия',
    setting: {},
  },
  {
    id: '2s3fdd',
    hostName: 'Въездная группа 78',
    date: '2024-07-03T10:15:30+03:00',
    clientId: 'KF1235FG',
    sum: '350,00',
    rrn: '411485063086',
    authorizationCode: '321791',
    cardNumber: '220220******6513',
    sessionNumber: '1212323',
    paymentMethod: 'Банковская карта',
    paymentType: 'Парковочная сессия',
    setting: {},
  },
  {
    id: '2s3fdd',
    hostName: 'Въездная группа 13',
    date: '2024-07-03T10:15:30+03:00',
    clientId: 'KF1235FG',
    sum: '350,00',
    rrn: '411485063086',
    authorizationCode: '321791',
    cardNumber: '220220******6513',
    sessionNumber: '1212323',
    paymentMethod: 'Банковская карта',
    paymentType: 'Парковочная сессия',
    setting: {},
  },
  {
    id: '2s3fdd',
    hostName: 'Въездная группа 73',
    date: '2024-07-03T10:15:30+03:00',
    clientId: 'KF1235FG',
    sum: '350,00',
    rrn: '411485063086',
    authorizationCode: '321791',
    cardNumber: '220220******6513',
    sessionNumber: '1212323',
    paymentMethod: 'Банковская карта',
    paymentType: 'Парковочная сессия',
    setting: {},
  },
];
