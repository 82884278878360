import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import './client-page.scss';
import {
  defaultFilterValues, data, zonesOptions, defaultColumns,
  typeClientOptions,
} from '../moks';
import { getColumnsForTable } from '../../../utils/getColumnsForTable';
import { Icons } from '../../../components/base/BootstrapComponents';
import TextInput from '../../../components/fields/TextInput/TextInput';
import FieldWithAttributeDTO from '../../../components/fields/Abstract/FieldWithAttributeDTO';
import PageHeader from '../../../components/base/PageHeader/PageHeader';
import CustomSelectInput from '../../../components/fields/SelectInput/CustomSelectInput';
import ClientLogGrid from './ClientGrid';
import ClientDetailsPopUp from './ClientDetailsPopUp';
import ClientLogFilterForm from './ClientFilterForm';
import SettingColumn from '../../../components/base/SettingColumn/SettingColumn';
import IconBox from '../../../components/common/IconBox';
import CustomButton from '../../../components/base/Button/CustomButton';
import SeparateBox from '../../../components/base/SeparateBox/SeparateBox';
import ClientForm from './ClientForm';
import ClientAddPopUp from './ClientAddPopUp';

export default function ClientPage() {
  const [isPopup, setIsPopup] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selected, setSelected] = useState([]);
  const [mode, setMode] = useState('');
  const [isOpenSettingColumn, setSettingColumn] = useState(false);
  const sessionLogColumn = getColumnsForTable(defaultColumns);
  const handlerClickRow = item => {
    setSelectedRow(item);
  };

  const loadDataFromStorage = () => {
    const storage = JSON.parse(localStorage.getItem('columns-filter'));
    return storage ? storage.map(column => {
      const search = sessionLogColumn.find(e => e.dataField === column.name);
      return { ...search, ...column };
    }).sort((a, b) => a.top - b.top) : sessionLogColumn;
  };

  const [columns, setColumns] = useState(loadDataFromStorage());

  const {
    register, getValues, watch, setValue, reset,
  } = useForm({
    defaultValues: JSON.parse(localStorage.getItem('client-filter')) || { ...defaultFilterValues },
  });

  watch();

  return (
    <div className="client-page">
      <div className="client-container">
        <div className="client-inputs-box">
          <div className={classNames('client-title-page', { variant: mode })}>
            {
              mode === 'create' || mode === 'edit' ? (
                <div className="path">
                  <span className="path-main" onClick={() => { setMode(null); setSelectedRow(null); }}>Клиенты</span> / <span>{mode === 'edit' ? 'Редактирование карточки клиента' : 'Добавление нового клиента'}</span>
                </div>
              ) : null
            }
            <PageHeader header={mode === 'edit' ? `Редактирование карточки клиента ${selectedRow.clientId}` : mode === 'create' ? 'Добавление нового клиента' : 'Клиенты'} />
          </div>
          { !mode ? (
            <div className="client-inputs">
              <FieldWithAttributeDTO
                inputBefore={<Icons customIcon className="search-icon" width={24} height={24} icon="search" />}
                type="text"
                component={TextInput}
                dtoObject={{
                  name: 'query',
                  type: String,
                  validators: [],
                  labelKey: 'search',
                }}
                inputProps={{
                  ...register('query', { maxLength: 30 }),
                  value: getValues('query'),
                  onChange: e => {
                    setValue('query', e.target.value);
                    localStorage.setItem('client-filter', JSON.stringify({ ...getValues() }));
                  },
                }}
                className="small no-border search-field "
              />
              <FieldWithAttributeDTO
                type="text"
                component={TextInput}
                placeholder="ИД клиента"
                dtoObject={{
                  name: 'clientId',
                  type: String,
                  validators: [],
                  labelKey: 'client.clientId',
                }}
                inputProps={{
                  ...register('sessionNumber', { maxLength: 30 }),
                  value: getValues('sessionNumber'),
                  onChange: e => {
                    setValue('sessionNumber', e.target.value);
                    localStorage.setItem('client-filter', JSON.stringify({ ...getValues() }));
                  },
                }}
                className="small no-border default-field"
              />
              <FieldWithAttributeDTO
                type="text"
                dtoObject={{
                  name: 'typeClient',
                  type: String,
                  validators: [],
                  labelKey: 'client.typeClient',
                }}
                placeholder="Тип клиента"
                inputProps={{
                  ...register('typeClient', { maxLength: 30 }),
                  onChange: e => {
                    setValue('typeClient', e.target.value);
                    localStorage.setItem('client-filter', JSON.stringify({ ...getValues() }));
                  },
                  value: Number(getValues('typeClient')) || '',
                }}
                component={CustomSelectInput}
                options={typeClientOptions}
                className="select-field no-border"
              />
              <SeparateBox />
              <IconBox icon="export" />
              <SeparateBox />
              <IconBox icon="filter" onClick={() => { setIsOpenFilter(true); }} />
              <SeparateBox />
              <IconBox icon="plus-add" onClick={() => setIsPopup(true)} />
              {selected.length ? <SeparateBox /> : null}
              {selected.length ? <CustomButton text="Удалить" onClick={() => {}} icon="trash" /> : null}
              {isOpenFilter
                ? (
                  <ClientLogFilterForm
                    onHide={() => { setIsOpenFilter(false); }}
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                    reset={reset}
                    defaultValues={defaultFilterValues}
                  />
                )
                : null}
              {isOpenSettingColumn
                ? (
                  <SettingColumn
                    onHide={() => { setSettingColumn(false); }}
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                    reset={reset}
                    setColumns={setColumns}
                    columns={columns}
                    defaultValues={{}}
                  />
                )
                : null}
            </div>
          ) : (
            <div className="client-inputs">
              <IconBox icon="archive" />
              <SeparateBox />
              <IconBox icon="trash" />
              <SeparateBox />
              <IconBox icon="lock-default" />
            </div>
          )}
        </div>
        { !mode || mode === 'views' ? <ClientLogGrid selected={selected} selectedItem={selectedRow} setMode={setMode} setSelected={setSelected} data={data} onClickByRow={handlerClickRow} columns={defaultColumns.filter(e => e.hidden !== false)} setSettingColumn={setSettingColumn} /> : null }
        { mode === 'edit' || mode === 'create' ? <ClientForm mode={mode} selectedItem={selectedRow} setSelectedRow={setSelectedRow} setMode={setMode} /> : null }
        { mode === 'views' ? <ClientDetailsPopUp setMode={setMode} onHide={() => setMode(false)} selectedItem={selectedRow} /> : null }
        { isPopup ? <ClientAddPopUp setMode={setMode} onHide={() => { setIsPopup(false); }} /> : null }
      </div>
    </div>
  );
}
